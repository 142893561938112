(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@emotion/core"), require("@emotion/styled"), require("@material-ui/icons"), require("@material-ui/icons/ExpandMore"), require("@mq/aurora-components/dist/checkbox"), require("@mq/aurora-components/dist/dropdown"), require("@mq/aurora-components/dist/input"), require("@mq/aurora-components/dist/menu"), require("@mq/aurora-components/dist/style.css"), require("gatsby"), require("react"), require("tslib"), require("use-query-params"));
	else if(typeof define === 'function' && define.amd)
		define(["@emotion/core", "@emotion/styled", "@material-ui/icons", "@material-ui/icons/ExpandMore", "@mq/aurora-components/dist/checkbox", "@mq/aurora-components/dist/dropdown", "@mq/aurora-components/dist/input", "@mq/aurora-components/dist/menu", "@mq/aurora-components/dist/style.css", "gatsby", "react", "tslib", "use-query-params"], factory);
	else if(typeof exports === 'object')
		exports["@mq/gatsby-components"] = factory(require("@emotion/core"), require("@emotion/styled"), require("@material-ui/icons"), require("@material-ui/icons/ExpandMore"), require("@mq/aurora-components/dist/checkbox"), require("@mq/aurora-components/dist/dropdown"), require("@mq/aurora-components/dist/input"), require("@mq/aurora-components/dist/menu"), require("@mq/aurora-components/dist/style.css"), require("gatsby"), require("react"), require("tslib"), require("use-query-params"));
	else
		root["@mq/gatsby-components"] = factory(root["@emotion/core"], root["@emotion/styled"], root["@material-ui/icons"], root["@material-ui/icons/ExpandMore"], root["@mq/aurora-components/dist/checkbox"], root["@mq/aurora-components/dist/dropdown"], root["@mq/aurora-components/dist/input"], root["@mq/aurora-components/dist/menu"], root["@mq/aurora-components/dist/style.css"], root["gatsby"], root["react"], root["tslib"], root["use-query-params"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__emotion_core__, __WEBPACK_EXTERNAL_MODULE__emotion_styled__, __WEBPACK_EXTERNAL_MODULE__material_ui_icons__, __WEBPACK_EXTERNAL_MODULE__material_ui_icons_ExpandMore__, __WEBPACK_EXTERNAL_MODULE__mq_aurora_components_dist_checkbox__, __WEBPACK_EXTERNAL_MODULE__mq_aurora_components_dist_dropdown__, __WEBPACK_EXTERNAL_MODULE__mq_aurora_components_dist_input__, __WEBPACK_EXTERNAL_MODULE__mq_aurora_components_dist_menu__, __WEBPACK_EXTERNAL_MODULE__mq_aurora_components_dist_style_css__, __WEBPACK_EXTERNAL_MODULE_gatsby__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_tslib__, __WEBPACK_EXTERNAL_MODULE_use_query_params__) {
return 